<template>
    <div class="container-fluid p-4"> 
        <div class="row">
            <CButton @click="showAddModalForm" color="success" class="btn-sm mb-2 ml-2">
                <CIcon name="cil-plus"/>
                    Tambah Baru
            </CButton>
            <hr class="bottom-devider"/>

        </div>
        <div class="row" v-if="dataExist">
            <div class="col-sm-12 col-md-12 col-lg-auto clickable" v-for="(data, index) in dataList" v-bind:key="index">
                <img @click="showEditModalForm(data)" :src="data.fileBase64"/>
            </div>
        </div>
        <div class="row">
            <div v-if="dataExist == false" class="col-12 text-center">
                Tidak ada data  
            </div>
            <div v-if="loadMore" class="col-12 text-center">
                <CButton @click="getSignatureBoxList()" color="danger" class="btn-sm mb-2">
                    Muat lebih banyak
                </CButton>
            </div>
            <div  class="col-12 text-center" v-if="loadingGetData">
                <v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
            </div>
        </div>
        <CModal :show.sync="showModalForm" 
                :closeOnBackdrop="false"
                class="modal-signatureBox">
            <template #header>
                <h5 class="modal-title">{{titleModalForm}}</h5>
                <CButtonClose @click="showModalForm = false" class="text-gray"/>
            </template>
            <div class="d-flex flex-column p-1">
                <div class="row">
                    <div class="col-auto middleCol">
                        <div
                            ref="capture"
                            class="lab__card"
                            style="padding: 5px; width: 12.3cm"
                            >
                            <div class="borderCard" style="padding: 5px; width: 12cm">

                            <div class="row" style="margin-bottom: 0px; height: 3.25cm; width: 12cm">
                                <div class="col-3" style="padding-bottom: 0px; padding-left: 20px">
                                    <img class="logo-img" :src="logo"/>
                                </div>
                                <div class="col-9" style="padding-bottom: 0px">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="font-custom-title" style="margin-top: 10px ;margin-bottom: 0px">
                                                Ditandatangani secara elektronik oleh: <br/> </p>
                                            <p class="font-custom" style="margin-bottom: 0px">
                                                {{signByLabel}} {{fillSignBy}} <br/>
                                                {{fillPositionName}} <br/>
                                                <b>{{fillFullName}}</b> <br/>
                                                {{fillRank}} &nbsp; {{fillNipOrNrp}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-if="showFormData">
                        <div class="lab__tools">
                            <div>
                                <CInput
                                    v-model="currentData.signBy"
                                    label="Atas Nama (Diisi jika perlu)" />
                                <CInput
                                    v-model="currentData.positionName"
                                    label="Jabatan" />
                                <CInput
                                    v-model="currentData.fullName"
                                    label="Nama Lengkap" />
                                <CInput
                                    v-model="currentData.rank"
                                    label="Pangkat" />
                                    
                                <CInputRadioGroup
                                    :options="radioOptions"
                                    :inline="true"
                                    :checked="radioChecked"
                                    style="margin-bottom: 8px"
                                    @update:checked="radioButtonNIPNRPClicked($event)"/>
                                <CInput
                                    v-model="currentData.nipOrNrp"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <template #footer>
                <div class="row">                
                    <div class="col-12">
                    <CButton v-if="stateForm == SHOW" @click="onDeleteButtonClick" color="danger" class="btn-sm float-left">
                    <font-awesome-icon v-if="loader" icon="spinner" spin/> 
                    <font-awesome-icon v-else icon="trash" style="margin-right: 5px"/> 
                        Hapus
                    </CButton>
                    <CButton :disabled="disableSaveButton" v-if="showSaveButton" @click="onSaveButtonClick" color="success" class="btn-sm float-right">
                        <font-awesome-icon v-if="loader" icon="spinner" spin/> 
                        <font-awesome-icon v-else icon="save" style="margin-right: 5px"/> 
                            Simpan
                    </CButton>
                    <CButton v-if="showEditButton" @click="onEditButtonClick" color="success" class="btn-sm float-right">
                        <font-awesome-icon v-if="loader" icon="spinner" spin/> 
                        <font-awesome-icon v-else icon="edit" style="margin-right: 5px"/> 
                            Ubah Data
                    </CButton>
                    <CButton v-if="stateForm == SHOW" @click="onDownloadButtonClick" color="info" class="btn-sm float-right  mr-2">
                        <font-awesome-icon v-if="loader" icon="spinner" spin/> 
                        <font-awesome-icon v-else icon="download" style="margin-right: 5px"/> 
                            Unduh
                    </CButton>
                    </div>
                </div>

            </template>
        </CModal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
const logo = require('@/assets/img/logo.png')
import domtoimage from "dom-to-image-more";
export default {
    components: {
    },
    data() {
        return {
            name: "",
            quote: "",
            image: "",
            loader: false,
            logo,
            result: null,
            currentData: {
                positionName: "",
                fullName: "",
                rank: "",
                nipOrNrp: "",
                fileBase64: "",
                boxId: "",
                signBy: ""
            },
            defaultData: {
                positionName: "",
                fullName: "",
                rank: "",
                nipOrNrp: "",
                fileBase64: "",
                boxId: "",
                signBy: ""
            },
            showModalForm: false,
            titleModalForm: "",
            stateForm: '',
            CREATE: 1,
            EDIT: 2,
            SHOW: 3,
            dataList: [],
            dataExist: false,
            loadMore: false,
            loadingGetData: false,
            imageDataFormat: "data:image/png;base64,",
            showSaveButton: false,
            showEditButton: false,
            showFormData: false,
            signByLabel: "",
            isNIP: true,
            isNRP: false,
            
			radioOptions: ['NIP', 'NRP'],
            radioChecked: 'NIP',

            nipFormat: "NIP. ",
            nrpFormat: "NRP. "
        }
    },
    computed: {
        ...mapState('auth', {
            loggedUserId: 'user_id',
            loggedUserPosition: 'position',
            groups: 'groups',
            fullname: 'fullname',
            profile_picture: 'profile_picture_thumb_base64',
            email: 'email'
        }),

        fillSignBy() {
            return this.returnDefaultDataIfNull(this.currentData.signBy, '[Atas Nama]')
        },

        fillPositionName() {
            return this.returnDefaultDataIfNull(this.currentData.positionName, '[Nama Jabatan]')
        },
        
        fillFullName() {
            return this.returnDefaultDataIfNull(this.currentData.fullName, '[Nama Lengkap]')
        },
        
        fillRank() {
            return this.returnDefaultDataIfNull(this.currentData.rank, '[Pangkat]')
        },

        fillNipOrNrp() {
            var data =  this.returnDefaultDataIfNull(this.currentData.nipOrNrp, '[NIP/NRP]')
            if(this.isNIP && (this.currentData.nipOrNrp != "" && this.currentData.nipOrNrp != " ")) {
                return this.nipFormat + data
            } else if(this.isNRP && (this.currentData.nipOrNrp != "" && this.currentData.nipOrNrp != " ")) {
                return this.nrpFormat + data
            } else {
                return data
            }
        },

        disableSaveButton() {
            if( this.currentData.positionName != "" &&
                this.currentData.fullName != "" && 
                this.currentData.rank != "") {
                return false
            } else {
                return true
            } 
        }
    },  
    mounted () {
        this.initData()
    },

    methods: {

        initData() {
            this.dataList = []
            this.getSignatureBoxList()
        },

        getSignatureBoxList() {
            this.loadingGetData = true
            this.$store.dispatch(
                {
                    type: 'bsre/getSignatureBoxList',
                    pagination: {
                        limit: 3,
                        offset: this.dataList.length
                    },
                }
            ).then( res => {
                if (res) {
                    res.result.forEach((item) => {
                        var data = {
                            positionName: item.nama_jabatan,
                            fullName: item.nama_lengkap,
                            rank: item.pangkat,
                            nipOrNrp: item.nip,
                            fileBase64: this.imageDataFormat +  item.file_base64,
                            boxId: item.box_id,
                            signBy: item.atas_nama
                        }
                        this.dataList.push(data)
                    })

                    if(res.result.length > 0) {
                        this.dataExist = true
                    }
                    if(this.dataList.length < res.count) {
                        this.loadMore = true
                    } else {
                        this.loadMore = false
                    }
                }
            }).finally(()=> {
                this.loadingGetData = false
            })
			
        },
        returnDefaultDataIfNull(data, defaultData) {
            if(data == '') {
                return defaultData
            } else {
                return data
            }
        }, 

        onSaveButtonClick() {
            var message = "Apakah anda yakin ingin menambah data?"
            if(this.stateForm == this.EDIT) {
                message = "Apakah anda yakin ingin mengubah data?"
            }
            this.$confirm({
                auth: false,
                message: message,
                button: {
                    no: 'Tidak',
                    yes: 'Ya'
                },
                callback: confirm => {
                    if (confirm) {
                        this.loader = true;
                        if(this.currentData.nipOrNrp == "") {
                            this.currentData.nipOrNrp = " "
                        }
                        if(this.currentData.signBy == "") {
                            this.currentData.signBy = " "
                            this.signByLabel = ""
                        }
                        const capture = this.$refs.capture;
                        domtoimage
                            .toPng(capture)
                            .then((dataUrl) => {
                                this.currentData.fileBase64 = dataUrl.split(this.imageDataFormat)[1]
                                if(this.stateForm == this.CREATE) {
                                    //call api create

                                    this.createSignatureBox()
                                } else {
                                    //call api update

                                    this.updateSignatureBox()
                                }
                            })
                            .catch((error) => {
                                this.capturing = false;
                                console.error("oops, something went wrong!", error);
                            });
                    }
                }
            })

        },

        confirmToCloseForm() {
            var message = "Apakah anda yakin ingin menutup popup ini?"

            this.$confirm({
                auth: false,
                message: message,
                button: {
                    no: 'Tidak',
                    yes: 'Ya'
                },
                callback: confirm => {
                    if (confirm) {
                        this.showModalForm = false
                    } else {
                        this.stateForm = this.SHOW
                        this.showFormData = false
                        this.showSaveButton = false
                        this.showEditButton = true
                        if(this.currentData.signBy == " ") {
                            this.signByLabel = ""
                        }
                    }
                }
            })
        },
        
        addNIPOrNRP(nipnrkNumber) {
            if(nipnrkNumber == " ") {
                return nipnrkNumber
            } else if(this.isNIP) {
                return this.nipFormat + nipnrkNumber
            } else if(this.isNRP) {
                return this.nrpFormat + nipnrkNumber
            } else {
                return nipnrkNumber
            }
        },

        removeNIPorNRP(nipnrkNumber) {
            nipnrkNumber = nipnrkNumber.replace(this.nipFormat, "")
            nipnrkNumber = nipnrkNumber.replace(this.nrpFormat, "")
            return nipnrkNumber
        },
        
        createSignatureBox() {
            this.currentData.nipOrNrp = this.addNIPOrNRP(this.currentData.nipOrNrp)
            this.$store.dispatch(
                {
                    type: 'bsre/createSignatureBox',
                    currentData: this.currentData
                }
            ).then(res=> {
                this.currentData.boxId = res.box_id
                this.$toasted.success('Berhasil menambahkan data').goAway(3000)
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.currentData.nipOrNrp = this.removeNIPorNRP(this.currentData.nipOrNrp)
                this.loader = false
                this.confirmToCloseForm()
                this.initData()
            })
        },

        updateSignatureBox() {
            this.currentData.nipOrNrp = this.addNIPOrNRP(this.currentData.nipOrNrp)
            this.$store.dispatch(
                {
                    type: 'bsre/updateSignatureBox',
                    currentData: this.currentData
                }
            ).then(res=> {
                this.$toasted.success('Berhasil mengubah data').goAway(3000)
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal mengubah data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.currentData.nipOrNrp = this.removeNIPorNRP(this.currentData.nipOrNrp)
                this.confirmToCloseForm()
                this.loader = false
                this.initData()
            })
        },

        onDownloadButtonClick() {
            this.loader = true;
            var link = document.createElement("a")
            link.download = this.currentData.positionName+"-"+this.currentData.fullName+"-"+this.currentData.rank+"-"+this.currentData.nipOrNrp+".png"
            
            var isWithBase64Format = this.currentData.fileBase64.includes(this.imageDataFormat)
            if(isWithBase64Format) {
                link.href = this.currentData.fileBase64
            } else {
                link.href = this.imageDataFormat + this.currentData.fileBase64
            }
            
            link.click()
            this.loader = false
        },

        showAddModalForm() {
            this.currentData = Object.assign({}, this.defaultData);
            this.showModalForm = true
            this.titleModalForm = "Tambah Data Baru"
            this.stateForm = this.CREATE
            this.showSaveButton = true
            this.showEditButton = false
            this.showFormData = true
            this.signByLabel = "a.n "
        },

        showEditModalForm(data) {
            this.currentData = Object.assign({}, data);
            this.showModalForm = true
            this.titleModalForm = "Kotak Tanda Tangan"
            this.stateForm = this.SHOW
            this.showSaveButton = false
            this.showEditButton = true
            this.showFormData = false
            

            if(this.currentData.nipOrNrp.includes(this.nipFormat)) {
                this.currentData.nipOrNrp = this.removeNIPorNRP(this.currentData.nipOrNrp)
                this.isNIP = true
                this.isNRP = false
                this.radioChecked = 'NIP'
            } else if (this.currentData.nipOrNrp.includes(this.nrpFormat)) {
                this.currentData.nipOrNrp = this.removeNIPorNRP(this.currentData.nipOrNrp)
                this.isNIP = false
                this.isNRP = true
                this.radioChecked = 'NRP'
            } else {
                this.isNIP = true
                this.isNRP = false
            }
        },

        onEditButtonClick() {
            this.titleModalForm = "Ubah Data"
            this.showEditButton = false
            this.showSaveButton = true
            this.showFormData = true
            this.stateForm = this.EDIT
            if(this.currentData.nipOrNrp == " ") {
                this.currentData.nipOrNrp = ""
            }
            if(this.currentData.signBy == " ") {
                this.currentData.signBy = ""
                this.signByLabel = "a.n "
            }
        },

        onDeleteButtonClick() {
            this.$confirm({
                auth: false,
                message: "Anda yakin ingin menghapus data ini?",
                button: {
                    no: 'Tidak',
                    yes: 'Ya'
                },
                callback: confirm => {
                    if (confirm) {
                        this.deleteSignatureBox()
                    }
                }
            })
        },
        
        deleteSignatureBox() {
            this.$store.dispatch(
                {
                    type: 'bsre/deleteSignatureBox',
                    currentData: this.currentData
                }
            ).then(res=> {
                this.$toasted.success('Berhasil menghapus data').goAway(3000)
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal menghapus data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.loader = false
                this.showModalForm = false
                this.initData()
            })
        },

        radioButtonNIPNRPClicked(value) {
            if(value == 'NIP') {
                this.isNIP = true
                this.isNRP = false
            } else if (value == "NRP") {
                this.isNIP = false
                this.isNRP = true
            }
        }
        
  },
}
</script>

<style scoped>
.lab__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 100%;
  min-height: 448px;
  padding: 40px 50px;
}
.lab__card > p {
  font-size: 30px;
  font-style: italic;
  margin-bottom: 10px;
}
.lab__card > small {
  font-style: bold;
  float: right;
}
.lab__tools {
  border-radius: 9px;
  padding: 5px 10px;
}

.lab__card {
  width: 450px;
  height: auto ;
  padding: 10px;
}

.borderCard {
  background: white;
  border: solid 1px;
  background-color: white;

}
.logo-img {
    width: 2.5cm;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.2cm;
}

.middleCol {

    margin-left: auto;
    margin-right: auto;
    display: block;
}

.font-custom {
    font-size: 12;
    font-family: Arial;
    color: black;
    line-height: 1.3em;
    margin-bottom: 0 !important;
}
.font-custom-title {
    font-size: 11;
    font-family: Arial;
    color: black;
    line-height: 1.3em;
    margin-bottom: 0 !important;
}
</style>